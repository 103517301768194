import { connectHits } from "react-instantsearch-dom";
import {
  AspectRatio,
  Box,
  Flex,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { ArticleHits } from "./SearchHits";
import { SearchResults } from "./SearchResults";

export const SearchHitsOpenRange = connectHits<ArticleHits>(({ hits }) => {
  return (
    <Box bg="white" py={2} zIndex={50} mt={16}>
      <SearchResults>
        {hits.map((hit) => {
          return (
            <LinkBox key={`hit-${hit.objectID}`} mb={8}>
              <Flex alignItems="center" px={2} py={1}>
                <AspectRatio ratio={1} width="124px" mr={4} flexShrink={0}>
                  <Image
                    src={hit.mainimage.url}
                    objectFit="cover"
                    alt={hit.title}
                    fallback={<Box width="100%" height="100%" bg="brand"></Box>}
                  />
                </AspectRatio>
                <Box textAlign="left">
                  <NextLink href={`/article/${hit.slug}`} passHref>
                    <LinkOverlay fontSize="lg">{hit.title}</LinkOverlay>
                  </NextLink>
                  <Text fontSize="sm" color="gray.500">
                    {hit.categories?.map((category, index) => {
                      let divider = "";
                      if (index < hit.categories.length - 1) {
                        divider = " / ";
                      }
                      return `${category.title}${divider}`;
                    })}
                  </Text>
                </Box>
              </Flex>
            </LinkBox>
          );
        })}
      </SearchResults>
    </Box>
  );
});
