import { useTranslation } from "next-i18next";
import {
  Button,
  Box,
  AspectRatio,
  Heading,
  Text,
  VStack,
  Spinner,
  Center,
  Image,
  Link,
  useMediaQuery,
  Icon
} from "@chakra-ui/react";
import NextLink from "next/link";
import { useAccountBalanceQuery } from "generated/graphql";
import { useUser } from "@auth0/nextjs-auth0";
import { PostOnboardingDashboard } from "components/PostOnboardingDashboard";
import { useRouter } from "next/router";
import { FaApple } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";

export const Hero = () => {
  const { t } = useTranslation("home");
  const { locale } = useRouter();

  const [isSmall, isVerySmall] = useMediaQuery([
    "(max-width: 40em)",
    "(max-width: 350px)",
  ]);
  const { user, isLoading } = useUser();
  const [{ data: accData, fetching }] = useAccountBalanceQuery({
    variables: { locale },
    pause: !user,
  });
  const account = accData?.account?.[0];
  return (
    <Box
      d="flex"
      flexWrap="wrap-reverse"
      justifyContent="space-between"
      alignItems="center"
      backgroundImage={[
        "none",
        "none",
        "none",
        "none",
        "none",
        "/illustrations/illustration_solo.png",
      ]}
      borderTop="0px solid transparent"
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition={account?.onboarding_completed ? "-380px" : "-170px"}
      transition="all 0.3s ease-in-out"
      width="100%"
      overflow="hidden"
    >
      {account?.onboarding_completed ? (
        <PostOnboardingDashboard account={account} />
      ) : (
        <Box
          padding={["20px 0", "20px"]}
          textAlign="center"
          paddingLeft={[0, 0, 0, 0, 0, "0em"]}
          margin="0 auto"
        >
          {fetching ? (
            <Center height={["290px", "auto"]}>
              <Spinner />
            </Center>
          ) : (
            <>
              <Heading
                as="h1"
                variant="h1"
                fontSize={["3xl", "2xl", "2xl", "4xl"]}
                mt={[5, 4]}
                px={["1em", 0]}
              >
                {t("hero.header")}
                <Text variant="h2" display="block" mt={2} fontFamily="Questrial"
                  fontSize={["2xl", "xl", "xl", "3xl"]}
                  fontWeight="hairline"
                  lineHeight="1.4em">
                  <i>
                    {t("hero.headerTwo")}</i>
                </Text>
                <Text
                  as="span"
                  fontFamily="Questrial"
                  display="block"
                  fontSize={["sm", "sm", "xs", "md"]}
                  mt={2}
                  wordBreak="break-word"
                  fontWeight="normal"
                  lineHeight="1.4em"
                >
                  {t("hero.subline")}
                </Text>
                <Text
                  as="span"
                  fontFamily="Questrial"
                  display="block"
                  fontSize={["sm", "sm", "xs", "md"]}
                  mt={2}
                  wordBreak="break-word"
                  fontWeight="normal"
                  lineHeight="1.4em"
                >
                  {t("hero.sublineTwo")}
                </Text>
              </Heading>
              <VStack justifyContent='center' flexDirection="row" mt={12} mb={6}>

                <Link href="https://dearest.app.link/e/web-ios" isExternal>
                  <Button
                    mr={4}
                    mt={2}
                    px={12}
                    size="lg"
                    disabled={isLoading}
                    transition="all 0.2s ease-in-out"
                  >
                    <Icon as={FaApple} color="white" mr={3} fontSize={"xl"} />
                    {user ? t("hero.header") : t("hero.button")}
                  </Button>
                </Link>
                <Link href="https://dearest.app.link/e/web-android" isExternal>
                  <Button
                    ml={4}
                    px={[isVerySmall ? 3 : 6, 12]}
                    size="lg"
                    fontSize={isVerySmall ? "md" : "lg"}
                    disabled={isLoading}
                    transition="all 0.2s ease-in-out"
                  >
                    <Icon as={IoLogoGooglePlaystore} color="white" mr={3} fontSize={"xl"} />
                    {t("hero.buttonTwo")}
                  </Button>
                </Link>
              </VStack>
            </>
          )}
        </Box>
      )}
      <Box
        position="relative"
        transition="all 0.3s ease-in-out"
        margin={0}
        width={["100%", "100%", "auto"]}
        display={
          account?.onboarding_completed
            ? ["none", "none", "none", "none", "block"]
            : "block"
        }
      >
        <Image
          position="absolute"
          right={["0", "0", "0", "0"]}
          zIndex="-1"
          minWidth="320px"
          height={["0", "440px"]}
          objectFit="cover"
          alt="Welcome to Dearest"
          src="/illustrations/homepage_duo_bg.png"
          loading="eager"
          transition="all 0.2s ease-in-out"
          fallback={
            <AspectRatio width="100%" ratio={1}>
              <></>
            </AspectRatio>
          }
        />
        <Image
          position="absolute"
          left={["-240px", "-100px", "-100px", "-100px", "-280px"]}
          zIndex="-1"
          minWidth="320px"
          height={["320px", "440px"]}
          objectFit="cover"
          margin="0 auto"
          alt="Welcome to Dearest"
          src="/illustrations/squiggles.png"
          loading="eager"
          fallback={
            <AspectRatio width="100%" ratio={1}>
              <></>
            </AspectRatio>
          }
          transition="all 0.2s ease-in-out"
        />
        <Image
          minWidth="320px"
          height={["320px", "440px"]}
          margin="0 auto"
          objectFit="cover"
          alt="Welcome to Dearest"
          src={
            isSmall
              ? "/illustrations/illustration_solo.png"
              : "/illustrations/homepage_duo.png"
          }
          loading="eager"
          fallback={
            <AspectRatio width="100%" ratio={1}>
              <></>
            </AspectRatio>
          }
        />
      </Box>
    </Box>
  );
};
