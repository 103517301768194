import { useViewedArticlesQuery } from "generated/graphql";
import { useUser } from "@auth0/nextjs-auth0";

const useViewedArticles = () => {
  const user = useUser();
  const [{ data }] = useViewedArticlesQuery({
    pause: user.isLoading || !user.user
  });
  return (
    data?.account_article_view.map(view => view.article_id.toString()) || []
  );
};

export default useViewedArticles;
