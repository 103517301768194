import { Box, Link, Flex, Text, Center } from "@chakra-ui/react";
import { ArticleCard } from "components/ArticleCard";
import {
  AccountBalanceQuery,
  useArticleByArticleIdQuery,
} from "generated/graphql";
import { ArrayElement } from "helperTypes";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import NextLink from "next/link";

interface RecommendationStationProps {
  account: ArrayElement<AccountBalanceQuery["account"]>;
}

export const RecommendationStation = ({
  account,
}: RecommendationStationProps) => {
  const { t } = useTranslation("common");
  const { locale } = useRouter();
  const unreadArticles = account.recommended_articles.filter(
    (art) => art.lastViewed === null
  );
  const recommendedArticleIds = unreadArticles.length
    ? unreadArticles.map((art) => art.article_id)
    : account.recommended_articles.map((art) => art.article_id);
  const [{ data, fetching }] = useArticleByArticleIdQuery({
    variables: { id: recommendedArticleIds, locale, limit: 2 },
    pause: !recommendedArticleIds.length,
  });

  const articles = data?.articles?.sort((a, b) => {
    return (b?.rank || 9999) - (a?.rank || 9999);
  });  

  return (
    <Box mt={2}>
      <Box>
        <Text variant="newsreader" fontSize="xl" mb={3}>
          {t("recommendations.title")}{" "}
          <NextLink href="/profile/recommendations">
            <Link fontSize="xs">{t("recommendations.seeAll")}</Link>
          </NextLink>
        </Text>
        <Flex justifyContent="space-around" flexWrap="wrap">
          {!fetching && !data?.articles?.[0] ? (
            <Center height="228px">
              <Text>{t("recommendations.noneFound")}</Text>
            </Center>
          ) : (
            <>
              <ArticleCard article={articles?.[0]} loading={fetching} />
              <ArticleCard article={articles?.[1]} loading={fetching} />
            </>
          )}
        </Flex>
      </Box>
      {/*       <Box>
        <Heading as="h3" fontSize="xl" mt={8} mb={3}>
          Talk:
        </Heading>
        {account?.bookings.filter(booking => booking.isFuture).length ? (
          <Text>Your next session</Text>
        ) : (
          <>
            {user?.free_initial_session_booked ? (
              <Text>Find a coach</Text>
            ) : (
              <>
                <Text as="span" pl="70px">
                  No coaching sessions scheduled
                </Text>
                <Button display="inline-block" variant="ghost" size="sm" ml={2}>
                  Book now
                </Button>
              </>
            )}
          </>
        )}
      </Box> */}
    </Box>
  );
};
