import {
  Badge,
  Box,
  LinkBox,
  LinkOverlay,
  Image,
  Skeleton,
  AspectRatio,
  Flex,
  Icon,
  Text,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { ArticlesDetailFragmentFragment } from "generated/graphql";
import { imageUrl } from "utils/imageUrl";
import useViewedArticles from "utils/hooks/useViewedArticles";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "next-i18next";

interface ArticleCardProps {
  article?: ArticlesDetailFragmentFragment | null;
  loading?: boolean;
}

export const ArticleCard = ({ article, loading }: ArticleCardProps) => {
  const { t } = useTranslation("common");
  const viewedArticleIds = useViewedArticles();
  const image = imageUrl(
    article?.mainimage?.formats?.small?.url || article?.mainimage?.url
  );
  const hasQuiz = article?.quiz;
  if (!loading && !article) {
    return <Box></Box>;
  }
  return (
    <Box pt="40px" mx="auto">
      <LinkBox
        bg="grey.50"
        borderColor="grey.50"
        borderWidth="2px"
        borderStyle="solid"
        borderRadius={8}
        width={["300px", "340px"]}
        pt="1px"
      >
        <Skeleton
          maxWidth="240px"
          borderTopRadius={8}
          mx="auto"
          isLoaded={!loading}
        >
          <Image
            mt="-40px"
            width="100%"
            maxWidth="240px"
            height="160px"
            objectFit="cover"
            mx="auto"
            src={image}
            alt={article?.metaTitle}
            borderTopRadius={8}
            fallback={
              <AspectRatio
                width="100%"
                ratio={16 / 9}
                maxWidth="240px"
                height="160px"
              >
                <></>
              </AspectRatio>
            }
          />
        </Skeleton>
        <Box bg="white" py="7px" borderBottomRadius={8}>
          <Flex alignItems="center">
            <Box flexGrow={1}>
              <Box>
                <Badge
                  colorScheme={hasQuiz ? "orange" : "purple"}
                  bg="transparent"
                >
                  <Skeleton isLoaded={!loading}>
                    {hasQuiz
                      ? t("articleCard.quickQuiz")
                      : t("articleCard.deeperDive")}{" "}
                  </Skeleton>
                </Badge>
              </Box>
              <Box px="10px">
                <Skeleton isLoaded={!loading}>
                  <NextLink href={`/article/${article?.slug}`} passHref>
                    <LinkOverlay>
                      <Text maxWidth="sm">{article?.title || "Loading"}</Text>
                    </LinkOverlay>
                  </NextLink>
                </Skeleton>
              </Box>
            </Box>
            {viewedArticleIds.includes(article?.id || "") && (
              <Box textAlign="center" pr={4} pt={1}>
                <Icon boxSize="5" as={FaCheckCircle} color="gray.300" />
              </Box>
            )}
          </Flex>
        </Box>
      </LinkBox>
    </Box>
  );
};
