import { RecommendationStation } from "./RecommendationStation";
import { AccountBalanceQuery } from "generated/graphql";
import { Box, Heading, SlideFade } from "@chakra-ui/react";
import { ArrayElement } from "helperTypes";

interface PostOnboardingDashboardProps {
  account: ArrayElement<AccountBalanceQuery["account"]>;
}

export const PostOnboardingDashboard = ({
  account,
}: PostOnboardingDashboardProps) => {
  return (
    <Box
      padding={["20px 1em", "20px"]}
      textAlign="left"
      paddingLeft={["1em", "1em", "40px", "60px", "60px", "60px"]}
      margin="0 auto"
      transition="all 0.3s ease-in-out"
      flexGrow={[0, 0, 1]}
    >
      <SlideFade in={true} offsetY="20px" delay={0.4}>
        <Heading>{`Hello ${account?.given_name || "Dearest"}`}</Heading>
        <RecommendationStation account={account} />
      </SlideFade>
    </Box>
  );
};
