import {
  Box,
  InputGroup,
  Input,
  InputLeftElement,
  Link,
  Text
} from "@chakra-ui/react";
import { ChevronRightIcon, SearchIcon } from "@chakra-ui/icons";
import { RefObject, useState } from "react";
import { useTranslation } from "next-i18next";
import { Search } from "components/Search";

interface HomeSearchProps {
  categoriesRef: RefObject<HTMLHeadingElement>;
}

export const HomeSearch = ({ categoriesRef }: HomeSearchProps) => {
  const { t } = useTranslation("common");
  return (
    <Box
      textAlign="center"
      bg="beige"
      pt={12}
      pb={10}
      px={4}
      backgroundImage="url(/illustrations/illustration_left.png), url(/illustrations/illustration_right.png);"
      backgroundRepeat="no-repeat"
      backgroundPosition={["-1000px", "-1000px", "-1000px", "50px 65px,98% 30px"]}
    >
      <h2 style={{ fontSize: "2em" }}>{t("search.headline")}</h2>

      <Box width={700} maxWidth="100%" mx="auto">
        <Search />
      </Box>
      <Link
        onClick={() => {
          if (categoriesRef.current) {
            categoriesRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start"
            });
          }
        }}
      >
        {t("search.allCategories")} <ChevronRightIcon />
      </Link>
    </Box>
  );
};
