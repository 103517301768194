import algoliasearch from "algoliasearch/lite";
import { Box } from "@chakra-ui/react";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import { useRouter } from "next/router";
import { SearchInput } from "./SearchInput";
import { SearchHits } from "./SearchHits";
import { SearchHitsOpenRange } from "./SearchHitsOpenRange";
import { useState } from "react";
import { useEffect } from "react";

interface SearchProps {
  size?: "small" | "normal";
  query?: string;
  /** freeRangeResults - boolean - Allow the results to show on the page rather than a popover box attached to the form field */
  freeRangeResults?: boolean;
}

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || "",
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY || ""
);

const indexName = `${process.env.NODE_ENV}_articles`;

export const Search = ({ size, query, freeRangeResults }: SearchProps) => {
  const [value, setValue] = useState("");
  const { locale } = useRouter();
  return (
    <Box mb={size === "small" ? 0 : 8} position="relative">
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <Configure filters={`locale:${locale}`} distinct />
        <SearchInput
          value={value}
          setValue={setValue}
          size={size}
          query={query}
        />
        {value.length > 3 && (
          <>{freeRangeResults ? <SearchHitsOpenRange /> : <SearchHits />}</>
        )}
      </InstantSearch>
    </Box>
  );
};
