import { AspectRatio, Box, Center, Icon, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useRef } from "react";
import { useState } from "react";
import { FaPlay } from "react-icons/fa";

export const HomeVideo = () => {
  const { t } = useTranslation("home");
  const { locale } = useRouter();
  const [clicked, setClicked] = useState(false);
  const videoRef = useRef<HTMLVideoElement & HTMLDivElement>(null);
  return (
    <>
      <AspectRatio
        width={["100%", "100%", "40%"]}
        ratio={0.9}
        overflow="hidden"
      >
        <>
          <Box
            as="video"
            ref={videoRef}
            objectPosition="0 35%"
            controls={clicked}
            borderRadius="5% 96% 4% 96% / 26% 4% 26% 4% "
            borderWidth="4px"
            borderStyle="solid"
            borderColor="purple.500"
            poster="/welcomePoster.jpg"
            autoPlay
            playsInline
            muted={!clicked}
            crossOrigin="anonymous"
          >
            {locale == "de" && (
              <>
                <source
                  src="https://dearest-content-storage.fra1.cdn.digitaloceanspaces.com/app-content/4Lukas_Deutsch_Vertical.mp4-small.mp4"
                  type="video/mp4"
                />
                <track
                  default
                  src="https://dearest-content-storage.fra1.cdn.digitaloceanspaces.com/app-content/4Lukas-Deutsch-Vertical.mp4-small.vtt"
                  kind="subtitles"
                  label="Deutsch"
                  srcLang="de"
                />
              </>
            )}
            {locale !== "de" && (
              <>
                <source
                  src="https://dearest-content-storage.fra1.cdn.digitaloceanspaces.com/app-content/4Lukas_English_Vertical.mp4-small.mp4"
                  type="video/mp4"
                />
                <track
                  default
                  src="https://dearest-content-storage.fra1.cdn.digitaloceanspaces.com/app-content/4Lukas-English-Vertical.mp4-small.vtt"
                  kind="subtitles"
                  label="English"
                  srcLang="en"
                />
              </>
            )}

            {`Your browser is not supported.`}
          </Box>
          {!clicked && (
            <Center
              role="group"
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              borderRadius="5% 96% 4% 96% / 26% 4% 26% 4%"
              cursor="pointer"
              color="purple.500"
              _hover={{ color: "white" }}
              transition="all 0.2s ease-in-out"
              onClick={() => {
                setClicked(true);
                if (videoRef.current) {
                  videoRef.current.currentTime = 0;
                  videoRef.current?.play();
                }
              }}
            >
              <Box
                bg="rgba(0,0,0,0.6)"
                padding={6}
                borderRadius={6}
                position="relative"
                top="80px"
              >
                <Icon as={FaPlay} boxSize={8} mr={4} />{" "}
                <Text fontSize="lg" fontWeight="bold" as="span">
                  {t("hero.howDoesItWork")}
                </Text>
              </Box>
            </Center>
          )}
        </>
      </AspectRatio>
    </>
  );
};
