import {
  AspectRatio,
  Box,
  Flex,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
} from "@chakra-ui/react";
import { connectHits } from "react-instantsearch-dom";
import NextLink from "next/link";
import { SearchResults } from "./SearchResults";

export interface ArticleHits {
  title: string;
  slug: string;
  proOnly: boolean;
  author: {
    firstName: string;
    image: {
      url: string;
    };
  };
  description: string;
  mainimage: {
    url: string;
  };
  categories: { title: string; description: string }[];
}

export const SearchHits = connectHits<ArticleHits>(({ hits }) => {
  return (
    <Box
      bg="white"
      position="absolute"
      left={1}
      right={1}
      py={2}
      zIndex={50}
      borderRadius="0 0 6px 6px"
      borderColor="gray.200"
      borderWidth="0 1px 1px"
      maxHeight="300px"
      overflowY="scroll"
    >
      <SearchResults>
        {hits
          .filter((hit) => hit.categories?.length > 0)
          .map((hit) => {
            return (
              <LinkBox key={`hit-${hit.objectID}`}>
                <Flex alignItems="center" px={2} py={1}>
                  <AspectRatio ratio={1} width="56px" mr={4} flexShrink={0}>
                    <Image
                      src={hit.mainimage.url}
                      objectFit="cover"
                      alt={hit.title}
                      fallback={
                        <Box width="100%" height="100%" bg="brand"></Box>
                      }
                    />
                  </AspectRatio>
                  <Box textAlign="left">
                    <NextLink href={`/article/${hit.slug}`} passHref>
                      <LinkOverlay>{hit.title}</LinkOverlay>
                    </NextLink>
                    <Text fontSize="sm" color="gray.500">
                      {hit.categories?.map((category, index) => {
                        let divider = "";
                        if (index < hit.categories?.length - 1) {
                          divider = " / ";
                        }
                        return `${category.title}${divider}`;
                      })}
                    </Text>
                  </Box>
                </Flex>
              </LinkBox>
            );
          })}
      </SearchResults>
    </Box>
  );
});
