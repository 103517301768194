import { i18n, useTranslation } from "next-i18next";
import { initUrqlClient, withUrqlClient } from "next-urql";
import { useRouter } from "next/router";
import { createUrlqClient } from "utils/graphqlClient/createUrqlClient";
import { GetStaticProps } from "next";
import { ssrExchange } from "urql";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRef } from "react";
import Head from "next/head";
import {
  Box,
  List,
  ListItem,
  ListIcon,
  SlideFade,
  Heading,
  Button,
  Link
} from "@chakra-ui/react";
import {
  AllCategoriesDocument,
  AllCategoriesQuery,
  AllCategoriesQueryVariables,
  useAllCategoriesQuery,
} from "generated/graphql";
import { Layout } from "components/Layout";
import { ManualFeaturedArticleType } from "components/FeaturedArticle";
import { Hero } from "components/Hero";
import { Wrapper } from "components/Wrapper";
import { HomeSearch } from "components/HomeSearch";
import CategoryList from "components/CategoryList";
import { HomeVideo } from "components/HomeVideo";
import { FaHeart } from "react-icons/fa";
import { useUser } from "@auth0/nextjs-auth0";
import NextLink from "next/link";

const Index = () => {
  const { user } = useUser();
  const { t } = useTranslation("home");
  const { locale, defaultLocale } = useRouter();
  const [{ data, fetching, error }] = useAllCategoriesQuery({
    variables: { locale: locale || defaultLocale || "en" },
  });
  const categoriesRef = useRef<HTMLHeadingElement>(null);

  // admit defeat sometimes
  const featuredArticle = data?.featuredArticle
    ?.article as ManualFeaturedArticleType;

  return (
    <Layout variant="wrapperWideNoMargins">
      <Head>
        {/* #performance */}
        <link rel="preload" as="image" href="/illustrations/homepage_duo.png" />
        <link
          rel="preload"
          as="image"
          href="/illustrations/homepage_duo_bg.png"
        />
        <link rel="preload" as="image" href="/illustrations/squiggles.png" />
      </Head>
      <Hero />
      <HomeSearch categoriesRef={categoriesRef} />
      <SlideFade in={!user} offsetY="-20px" unmountOnExit>
        <Wrapper variant="wrapperNoMargins">
          <Box
            display="flex"
            flexWrap="wrap-reverse"
            justifyContent="space-between"
            mt={8}
          >
            <Box display="flex" flexGrow={1} alignItems="center">
              <Box width="100%">
                <Heading as="h3" mb={8} mt={[8, 8, 0]}>
                  {t("subArea.howCanDearestHelp")}
                </Heading>
                <List spacing="6" fontSize={["md", "md", "lg"]} px={6}>
                  <ListItem>
                    <ListIcon as={FaHeart} color="purple.500" mr={4} />

                    {t("subArea.getTips")}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaHeart} color="purple.500" mr={4} />
                    {t("subArea.recommendations")}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaHeart} color="purple.500" mr={4} />
                    {t("subArea.messageYourCoach")}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaHeart} color="purple.500" mr={4} />
                    {t("subArea.videoSessions")}
                  </ListItem>
                </List>
                <Box
                  display="flex"
                  justifyContent="space-around"
                  mt={12}
                  px={6}
                  flexWrap="wrap"
                >
                  <Link href="https://app.hellodearest.io/api/auth/login?returnTo=/welcome&screen_hint=signup">
                    <Button colorScheme="purple" size="lg" mb={[4, 0]}>
                      {t("subArea.registerNow")}
                    </Button>
                  </Link>
                  <NextLink href="/pricing">
                    <Button colorScheme="purple" variant="ghost" size="lg">
                      {t("subArea.viewPricing")}
                    </Button>
                  </NextLink>
                </Box>
              </Box>
            </Box>
            <HomeVideo />
          </Box>
        </Wrapper>
      </SlideFade>

      <Wrapper variant="wrapperNoMargins">
        <Box m={"40px 0"}>
          <Heading as="h4" fontSize="24px" ref={categoriesRef}>
            {t("categories")}
          </Heading>
          <CategoryList categories={data?.categories} fetching={fetching} />
        </Box>
      </Wrapper>
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async ({ params, locale }) => {
  if (process.env.NODE_ENV === "development") {
    await i18n?.reloadResources();
  }
  const ssrCache = ssrExchange({ isClient: false });
  const client = initUrqlClient(createUrlqClient(ssrCache), false);
  const res = await client
    ?.query<AllCategoriesQuery, AllCategoriesQueryVariables>(
      AllCategoriesDocument,
      { locale: locale || "en" }
    )
    .toPromise();

  let state = null;
  try {
    state = ssrCache.extractData();
    if (!res?.data) {
      state = null;
      console.log("AllCategoriesQuery failed");
      console.log({ queryOutput: res });
    }
  } catch (e) {
    console.log("extractData failed");
    console.log({ error: e });
  }

  return {
    props: {
      urqlState: state,
      ...(await serverSideTranslations(locale || "en", [
        "common",
        "category",
        "home",
      ])),
    },
  };
};

export default withUrqlClient(createUrlqClient)(Index);
