export const imageUrlWithSmall = (url?: string, fallback: boolean = true) => {
  if (!url) {
    return fallback
      ? "https://dearest-content-storage.fra1.cdn.digitaloceanspaces.com/small_94774518_fe53_459f_a76a_1a772facba5c_343d17e20c"
      : undefined;
  }

  const val = "digitaloceanspaces.com/";

  const split = url.split(val);

  return `${split[0]}${val}small_${split[1]}`.replace(
    "dearest-content-storage.fra1.digitaloceanspaces.com",
    "dearest-content-storage.fra1.cdn.digitaloceanspaces.com"
  );
};

export const imageUrl = (url?: string, fallback: boolean = true) => {
  if (!url) {
    return fallback
      ? "https://dearest-content-storage.fra1.cdn.digitaloceanspaces.com/small_94774518_fe53_459f_a76a_1a772facba5c_343d17e20c"
      : undefined;
  }
  return url.replace(
    "dearest-content-storage.fra1.digitaloceanspaces.com",
    "dearest-content-storage.fra1.cdn.digitaloceanspaces.com"
  );
};
