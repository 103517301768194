import {
  SimpleGrid,
  Skeleton,
  Link,
  Box,
  Image,
  Center,
  Text,
} from "@chakra-ui/react";
import { imageUrlWithSmall } from "utils/imageUrl";
import { AllCategoriesQuery } from "generated/graphql";
import NextLink from "next/link";
import { useTranslation } from "next-i18next";

interface CategoryListProps {
  categories: AllCategoriesQuery["categories"];
  fetching: boolean;
}

const CategoryList = ({ fetching, categories }: CategoryListProps) => {
  const { t } = useTranslation("category");
  if (!categories?.length && !fetching) {
    return (
      <Center bg="beige" height="200px" borderRadius="6px">
        <Text variant="subtitleNewsreader">{t("noCategories")}</Text>
      </Center>
    );
  }
  return (
    <SimpleGrid columns={[2, 2, 3]} spacing={4} mt={5}>
      {fetching &&
        Array.apply(null, Array(6)).map((arr, index) => {
          return (
            <Box key={`loading-category-${index}`}>
              <Skeleton>
                <Image
                  width="100%"
                  height={["200px", "300px"]}
                  objectFit="cover"
                  borderRadius={6}
                  alt="Loading"
                />
              </Skeleton>
              <Skeleton>
                <p>{t("loading")}</p>
              </Skeleton>
            </Box>
          );
        })}
      {categories &&
        categories.map((category) => (
          <Box key={`category-${category?.id}`}>
            <NextLink href={`/category/${category?.slug}`}>
              <Link>
                <Image
                  src={imageUrlWithSmall(category?.image?.url)}
                  fallback={
                    <Box
                      width="100%"
                      height={["200px", "300px"]}
                      background="beige"
                      borderRadius={6}
                    />
                  }
                  width="100%"
                  height={["200px", "300px"]}
                  objectFit="cover"
                  borderRadius={6}
                  alt={category?.title}
                />
                <p>{category?.title}</p>
              </Link>
            </NextLink>
          </Box>
        ))}
    </SimpleGrid>
  );
};

export default CategoryList;
