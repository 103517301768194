import { useTranslation } from "next-i18next";
import { Box, Spinner } from "@chakra-ui/react";
import { connectStateResults } from "react-instantsearch-dom";

export const SearchResults = connectStateResults(
  ({ searching, isSearchStalled, searchResults, children }) => {
    const { t } = useTranslation("common");
    if (searching) {
      return (
        <Box py={3} textAlign="center">
          <Spinner size="lg" />
        </Box>
      );
    }
    if ((searchResults && searchResults.nbHits === 0) || isSearchStalled) {
      return (
        <Box py={3} textAlign="center">
          {t("search.noResults")}
        </Box>
      );
    }
    return <>{children}</>;
  }
);
