import { connectSearchBox } from "react-instantsearch-dom";
import { SearchBoxProvided } from "react-instantsearch-core";
import {
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  Button,
  Icon,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useTranslation } from "next-i18next";
import { ChangeEvent, FunctionComponent, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useEffect } from "react";

interface SearchInputProps {
  value: string;
  setValue: (arg0: string) => void;
  size?: "small" | "normal";
  query?: string;
}

type SearchInputType = (
  props: FunctionComponent<SearchBoxProvided & SearchInputProps>
) => React.FunctionComponent<SearchInputProps> | React.ComponentClass;

const ExtendedConnectSearchBox: SearchInputType = connectSearchBox;

export const SearchInput = ExtendedConnectSearchBox(
  ({ refine, value, setValue, size, query }) => {
    const { t } = useTranslation("common");
    const [debouncedChange, setDebouncedChange] = useState<
      ReturnType<typeof setTimeout>
    >();

    useEffect(() => {
      if (query) {
        setValue(query);
        refine(query);
      }
    }, [query, setValue, refine]);

    const onDebouncedChange = (event: ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value },
      } = event;
      if (debouncedChange) {
        clearTimeout(debouncedChange);
      }
      if (value && value.length > 2) {
        setDebouncedChange(
          setTimeout(() => {
            refine(value);
          }, 250)
        );
      }
      setValue(value);
    };

    return (
      <InputGroup mt={size === "small" ? 0 : 8}>
        <InputLeftElement pointerEvents="none">
          <SearchIcon height="100%" boxSize="20px" color="gray.500" />
        </InputLeftElement>
        <Input
          placeholder={t("search.placeholder")}
          size="lg"
          variant="outline"
          bg="white"
          value={value}
          onChange={onDebouncedChange}
          focusBorderColor="orange.200"
          _placeholder={{ color: "grey.900" }}
        />
        {value.length && (
          <InputRightElement height="100%" mr={2}>
            <Button variant="ghost" size="sm" onClick={() => setValue("")}>
              <Icon as={FaTimes} />
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
    );
  }
);
